//
// sidebar & sidecontent 
//
//

.widget {
	+ .widget { margin-top: rem(30); }
}

.widget-title {
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: rem(10);
	text-transform: uppercase;
	font-weight: $font-weight-bold;
	color: $black;
}