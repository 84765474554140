//
// testimonials
//
//
 
.testimonial {
	position: relative;

	p {
		margin-bottom: rem(10);
		color: $black;
	}

	footer {
		font-size: rem(12);
		letter-spacing: .05em;
	}
}

.testimonial-rate {
	margin-bottom: rem(10);

	> span {
		font-size: rem(12);
		color: $yellow;

		&:not(:first-child) {
			margin-left: rem(5);
		}
	}
}