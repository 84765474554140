//
// boxes
//
//


%boxed,
%bordered {
	@include transition;
	position: relative;
}

%boxed { 
	background: $white;
}

%bordered { 
	border: $border-width solid $border-color; 
} 

[class*="boxed"] { @extend %boxed; }
[class*="bordered"] { @extend %bordered; }