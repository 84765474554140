//
//	partners
//
//
//

.partners {
	position: relative;

	.owl-stage-outer {

		.owl-stage {
			display: -ms-flexbox!important;
			display: flex !important;
			align-items: center;

			.owl-item {

				a {
					@include transition;
					text-align: center;
					height: rem(150);
					display: block;
					border: $border-width solid $border-color;

					&:hover {
						border-color: $primary;
					}
				}
				
				img {
					@include translate(0,-50%);
					position: relative;
					top: 50%;
					display: inline-block;
					width: initial;
					max-width: 100%;
				}

			}
		}

	}

	
	.owl-carousel .owl-nav {
		.owl-prev,
		.owl-next {
			border: $border-width solid $border-color;

			&:hover {
				background: $white;
				border-color: $primary;

				&::before {
					color: $primary;
				}
			}
		}
	}

}