//
// cart
//
//

.cart-item-list {
	.cart-item {
		+ .cart-item {
			margin-top: rem(20);
			padding-top: rem(20);
			border-top: $border-width solid $border-color;
		}
	}
}

.cart-item {
	display: flex;

	.dropdown-toggle {
		font-size: $font-size-sm;
		color: $gray-400;

		span {
			color: $black;
		}
	}

	img {
		max-width: rem(80);
	}

	+ .cart-item {
		margin-top: rem(10);
	}
}

.cart-item-body {
	position: relative;
	flex: 1;
	padding-left: $card-spacer-y;
}

.cart-item-title {
	margin-bottom: 0;
	font-size: $font-size-base;
}

.cart-item-subtitle {
	color: $gray-400;
}

.cart-item-meta {
	display: block;
	margin-top: rem(5);
	font-size: $font-size-sm;
	font-weight: $font-weight-bold;

	* {
		display: inline-block;
	}

	s {
		font-weight: $font-weight-normal;
	}
}

.cart-item-options {
	display: inline-block;

	a {
		@include transition;
		display: block;
		width: rem(30);
		height: rem(30);
		line-height: rem(30);
		text-align: center;
		font-size: $font-size-lg;
		color: $gray-300;
		border: $border-width solid $border-color;
		border-radius: 50%;

		&:hover {
			color: $black;
			border-color: $black;
		}
	}
}