//
// code
//
//


code[class*="language-"], 
pre[class*="language-"] {
  font-family: $font-family-monospace;
  font-size: $font-size-sm;
  text-shadow: none;
}

pre[class*="language-"] {
  padding: $card-spacer-x;
  background: transparent;
  border: $border-width solid $border-color;
}

div.code-toolbar {

  &:hover {
    .toolbar {
      opacity: 1;
    }
  }

  > .toolbar {
    opacity: 0;
    top: 0;
    right: 0;

    .toolbar-item {
      display: block;
    }

    a {
      @include transition;
      @include no-shadow;
      display: block;
      padding: rem(5) rem(10);
      font-size: rem(12);
      line-height: rem(20);
      background: $border-color;
      color: $black;
      border-radius: 0;
      text-transform: uppercase;
      font-weight: $font-weight-normal;

      &:hover {
        color: $white;
        background: $primary;
      }
    }
  }
}


.documentation {
  section + section {
    margin-top: rem(40);
    padding-top: rem(40);
    border-top: $border-width solid $border-color;
  }
}


// changelog
.changelog {
  margin-left: rem(10);
  padding-left: rem(40);
  padding-bottom: rem(40);
  border-left: $border-width solid $border-color;

  > li {
    position: relative;

    &:not(:first-child) {
      margin-top: rem(40);
    }

    &::before {
      position: absolute;
      top: 0;
      left: rem(-50);
      content: "";
      width: rem(20);
      height: rem(20);
      border-radius: 50%;
      background: $border-color;
    }
  }

  ol {
    font-size: $font-size-sm;
  }
}

.log {
  @include translate(0,-10px);
  margin-bottom: rem(20);

  .log-title {
    margin-bottom: 0;
    font-size: rem(24);
    line-height: 1em;
  }

  .log-time {
    font-size: rem(12);
    text-transform: uppercase;
  }
}


// component
.component {
  margin-top: rem(30);
}

.component-title {
  font-size: rem(20);
  margin-bottom: rem(20);
}

.component-demo {
  padding: $card-spacer-x;
  border: $border-width solid $light;
}

.component-tabs {
  .tab-content {
    margin-top: rem(10);
  }
}

.component-description {
  margin-bottom: 0;
}

.component-info {
  padding: $card-spacer-x;
  background: $light;
  margin-top: 0!important;

  code {
    font-weight: $font-weight-normal;
    color: $primary;
  }

  pre[class*="language-"] {
    padding: 0;
    background: transparent;
    border: 0;
  }
}