//
// colors
//
//


@each $color, $value in $colors {
  @include bg-variant(".bg-#{$color}", $value, true);
}



@each $color, $value in $colors {
  @include text-emphasis-variant(".text-#{$color}", $value, true);
}

@each $color, $value in $socials {
  .text-hover-#{$color} {
  	@include transition;
  	
    &:hover { 
    	color: #{$value}!important;
    }
  }
}